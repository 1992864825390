//
// custom-variables.scss
//

// Left Sidebar
$sidebar-width:                     var(--#{$prefix}sidebar-width);
$sidebar-width-sm:                  var(--#{$prefix}sidebar-width-sm);

$menu-link-item-gap:                var(--#{$prefix}menu-link-item-gap);

$menu-item-padding-x:               var(--#{$prefix}menu-item-padding-x);
$menu-item-gap:                     var(--#{$prefix}menu-item-gap);

$menu-link-padding-y:               var(--#{$prefix}menu-link-padding-y);
$menu-link-padding-x:               var(--#{$prefix}menu-link-padding-x);

$menu-item-icon-size:               var(--#{$prefix}menu-item-icon-size);
$menu-item-font-size:               var(--#{$prefix}menu-item-font-size);

// Menu background and text color
$menu-bg:                           var(--#{$prefix}menu-bg);
$menu-item-color:                   var(--#{$prefix}menu-item-color);
$menu-item-hover-color:             var(--#{$prefix}menu-item-hover-color);
$menu-item-active-color:            var(--#{$prefix}menu-item-active-color);
$menu-item-active-bg:               var(--#{$prefix}menu-item-active-bg);

// Topbar
$topbar-height:                     var(--#{$prefix}topbar-height);
$topbar-bg:                         var(--#{$prefix}topbar-bg);
$topbar-item-color:                 var(--#{$prefix}topbar-item-color);
$topbar-item-hover-color:           var(--#{$prefix}topbar-item-hover-color);

// Horizontal Menu
$horizontal-menu-height:            var(--#{$prefix}horizontal-menu-height);